@import-normalize;

html,
body,
#root {
  appearance: none;
  background: var(--color-bg);
  font-size: 16px;
  height: 100%;
  margin: 0;
  width: 100%;
}

*, *::after, *::before {
  appearance: none;
  box-sizing: border-box;
}

*::placeholder {
  color: var(--color-placeholder);
}

*:focus,
*:active,
*:target,
*:hover {
  outline: none!important;
}

*::selection {
  color: var(--color-bg);
  background: papayawhip;
}

// remove chrome autofill blue/yellow background.
*:-webkit-autofill,
*:-internal-autofill-previewed,
*:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

html {
  overflow: hidden;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overscroll-behavior: none;
}

a, p, span, h1, h2, h3, div, aside, nav, figure, blockquote, input, label, option, button {
  color: var(--color-text-1);
  font-family: var(--font-primary);
  line-height: 130%;
  margin: 0;
}

a {
  font-size: var(--font-base);
  text-decoration: none;
}

p {
  font-size: var(--font-base);
}

h1 {
  font-size: var(--font-large);
}

span {
  font-size: var(--font-small);
}

code {
  font-family: var(--font-mono);
}

div, aside, nav, figure, blockquote, input, label, option, button, code, pre {
  font-size: var(--font-base);
}
