:root, [data-theme="default"] {
  /* dimensions. */
  --dimension-header-height: 56px;
  --dimension-space-sm: 5px;
  --dimension-space-md: 10px;
  --dimension-space-lg: 15px;
  --dimension-space-xl: 20px;

  /* colors. */
  --hue: 220;
  --color-primary: hsl(calc(var(--hue) - 185), 100%, var(--lightness, 95%));
  --color-secondary: hsl(calc(var(--hue) - 120), 70%, 75%);
  --color-bg: hsl(var(--hue), 25%, 15%);
  --color-fg: hsl(var(--hue), 100%, 3%);
  --color-placeholder: #3a3836;
  --color-text-1: white;
  --color-text-2: var(--color-bg);

  /*
   * fonts using minor third scale.
   * https://www.modularscale.com/?1&rem&1.2
  */
  --font-base: 1rem;
  --font-large: 1.2rem;
  --font-larger: 1.44rem;
  --font-largest: 1.728rem;
  --font-small: 0.833rem;
  --font-smallest: 0.694rem;

  --font-primary: helvetica, arial, sans-serif;
  --font-secondary: lucida;
  --font-icon: 'Font Awesome 5 Pro';
  --font-mono: source-code-pro, Menlo, Monaco, Consolas, "Courier New";

  /* z-indices. */
  --z-below: -1;
  --z-bottom: 0;
  --z-above-bottom: 5;
  --z-middle: 10;
  --z-above-middle: 15;
  --z-top: 20;
  --z-troposphere: 999999;
}
